import { renderSlot as _renderSlot, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full flex" }
const _hoisted_2 = {
  style: {"width":"415px"},
  class: "print:hidden"
}
const _hoisted_3 = { class: "flex-1 min-h-screen bg-gray-300 py-8 print:py-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "aside")
    ]),
    _createVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default")
    ])
  ]))
}