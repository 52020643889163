
import { AlertaDTO } from "@/dtos/AlertaDTO";
import { format } from "date-fns";
import { computed, defineComponent, PropType } from "vue";

const Irregularity = defineComponent({
    props: {
        showIrregularityTime: {
            type: Boolean,
            default: true,
        },
        showAuditResponsable: {
            type: Boolean,
            default: true,
        },
        showAuditTime: {
            type: Boolean,
            default: true,
        },
        alert: {
            type: Object as PropType<AlertaDTO>,
            required: true,
        },
    },
    setup(props) {
        const initialTime = computed(() =>
            format(new Date(0, 0, 0, 0, 0, 0, props.alert.start), "HH:mm:ss")
        );
        const finalTime = computed(() =>
            format(new Date(0, 0, 0, 0, 0, 0, props.alert.end), "HH:mm:ss")
        );

        const statusName = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "Liberado";
                case "BLOCKED":
                    return "Indício de fraude";
                case "SYSTEM_FAILURE":
                    return "Inválido";
                default:
                    return "Não auditado";
            }
        });

        const statusIcon = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "check-circle";
                case "BLOCKED":
                    return "times-circle";
                case "SYSTEM_FAILURE":
                    return "alert";
                default:
                    return "cross-eye";
            }
        });

        const statusColor = computed(() => {
            switch (props.alert.alertStatus) {
                case "RELEASED":
                    return "#04986D";
                case "BLOCKED":
                    return "#D95454";
                case "SYSTEM_FAILURE":
                    return "#DF945D";
                default:
                    return "#363738";
            }
        });

        const tradutorAlerta = (
            description: 
            "Manual" 
            | "NoFace" 
            | "MultipleFace" 
            | "OtherFace" 
            | "HeadUp" 
            | "HeadDown" 
            | "HeadRight" 
            | "HeadLeft"
            | "HeadRolled" 
            | "NoGaze" 
            | "GazeUp" 
            | "GazeDown" 
            | "GazeLeft" 
            | "GazeRight" 
            | "Audio"
            | "CellPhone"
            | "OpenMouth"
            | "EnvironmentMultiplePeople"
            | "EnvironmentNoPeople"
            | "ExtractFailed"
            | "ShortExam"
            | "VeryShortExam"
        ) => {
            switch (description) {
                case "Manual":
                    return "Manual";
                case "NoFace":
                    return "Nenhuma face encontrada";
                case "MultipleFace":
                    return "Múltiplas faces encontradas";
                case "OtherFace":
                    return "Face de outra pessoa encontrada";
                case "HeadUp":
                    return "Cabeça para cima";
                case "HeadDown":
                    return "Cabeça para baixo";
                case "HeadRight":
                    return "Cabeça para direita";
                case "HeadLeft":
                    return "Cabeça para esquerda";
                case "HeadRolled":
                    return "Cabeça inclinada";
                case "NoGaze":
                    return "Nenhum olhar detectado";
                case "GazeUp":
                    return "Olhar direcionado para cima";
                case "GazeDown":
                    return "Olhar direcionado para baixo";
                case "GazeLeft":
                    return "Olhar direcionado para esquerda";
                case "GazeRight":
                    return "Olhar direcionado para direita";
                case "Audio":
                    return "Audio encontrado";     
                case "CellPhone":
                    return "Celular detectado";
                case "OpenMouth":
                    return "Boca aberta";
                case "EnvironmentMultiplePeople":
                    return "Múltiplas pessoas encontradas";
                case "EnvironmentNoPeople":
                    return "Nenhuma pessoa encontrada"; 
                case "ExtractFailed":
                    return "Não foi possivel extrair informação biométrica";
                case "ShortExam":
                    return "Duracao do exame abaixo de 15min";
                case "VeryShortExam":
                    return "Duração do exame abaixo de 10min";                                            
                default:
                    return description;
            }
        };

        const audited = computed(() => props.alert.auditorId != null);

        const auditDate = computed(() => {
            return audited.value ? new Date(props.alert.date) : null;
        });

        const auditDateString = computed(() =>
            auditDate.value
                ? format(auditDate.value, "dd/MM/yyyy")
                : new Date().toLocaleDateString()
        );
        const auditTimeString = computed(() =>
            auditDate.value ? format(auditDate.value, "HH:mm:ss") : ""
        );

        return {
            initialTime,
            finalTime,
            statusName,
            statusIcon,
            statusColor,
            auditDateString,
            auditTimeString,
            tradutorAlerta
        };
    },
});

export default Irregularity;
